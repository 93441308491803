

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class InsectPest extends Vue {
  private dataRange = []
  private types = ['维保中', '待确认', '已完成', '已关闭']
  private searchInfo = {
    startDate: '',
    endDate: '',
    maintenanceState: '',
    maintenanceNameCode: '',
    machineryId: this.$route.query.id
  }

  private loading = false
  private tableData = []
  private total = 0
  private page = 1
  private size = 10

  created () {
    this.loadData()
  }

  activated () {
    this.onSearch()
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  loadData () {
    this.loading = true
    if (this.dataRange) {
      this.searchInfo.startDate = this.dataRange[0]
      this.searchInfo.endDate = this.dataRange[1]
    } else {
      this.searchInfo.startDate = ''
      this.searchInfo.endDate = ''
    }
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryMaintenanceByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onDetail (maintainId: any) {
    console.log(maintainId)
    this.$router.push({
      path: '/machineryManage/upkeep',
      query: {
        maintainId
      }
    })
  }
}
